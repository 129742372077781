/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { usePageSpaceKey } from '@confluence/page-context';

import type {
	useSpaceHomepageIdSpaceHomepageIdQuery as useSpaceHomepageIdSpaceHomepageIdQuery$data,
	useSpaceHomepageIdSpaceHomepageIdQueryVariables as useSpaceHomepageIdSpaceHomepageIdQuery$variables,
} from './__types__/useSpaceHomepageIdSpaceHomepageIdQuery';

export const useSpaceHomepageId = (spaceKey?: string): string | undefined => {
	const [pageContextSpaceKey] = usePageSpaceKey();
	spaceKey = spaceKey ?? pageContextSpaceKey;
	const { data } = useQuery<
		useSpaceHomepageIdSpaceHomepageIdQuery$data,
		useSpaceHomepageIdSpaceHomepageIdQuery$variables
	>(
		gql`
			query useSpaceHomepageIdSpaceHomepageIdQuery($spaceKey: String) {
				space(key: $spaceKey) {
					homepage {
						id
					}
				}
			}
		`,
		{
			variables: { spaceKey },
			skip: !spaceKey,
		},
	);
	return data?.space?.homepage?.id || undefined;
};
